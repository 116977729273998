<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Chatbot Einstellungen</strong>
              <DoubleBounceLoader v-if="loading || saving" width="20px" height="20px" style="float: right"/>
            </slot>
          </CCardHeader>
          <CCardBody v-if="!loading">
            <CTabs>
              <CTab active>
                <template slot="title">
                  Chatbot
                </template>
                <br>
                <CRow>
                  <CCol xs="12" xl="10">
                    <Checkbox
                            label="Chatbot aktiv?"
                            name="chatbotActive"
                            :options="['Aktiv']"
                            @input="chatbot.active = Array.isArray($event) && $event.includes('Aktiv')"
                            :value="computedChatbotActive"
                            description="Aktiviere die Chatbot-Integration"
                    />
                    <CTextarea
                            type="textarea"
                            label="Begrüßung / Einwilligung zum Datenschutz"
                            description="Bitte geben Sie eine Begrüßung sowie Informationen zum Datenschutz ein. Bitte beachten Sie, dass der Text mit einer Frage, die mit Ja oder Nein zu beantworten ist endet (Datenschutzrechtliche Einwilligungserklärung)."
                            v-model="chatbot.welcomeDsgvo"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage nach dem Vorgesetztem/Manager"
                            description='Fragen wie: "hast du einen Manager?" oder "hast du einen Arbeitgeber"'
                            v-model="chatbot.smalltalkMaklerBoss"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage nach der Datenschutzerklärung"
                            description='Fragen wie: "Wo ist ihre Datenschutzerklärung?" oder "Datenschutzerklärung"'
                            v-model="chatbot.smalltalkMaklerDatenschutz"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage zu den Dienstleistungen"
                            description='Fragen wie: "Welche Dienstleistungen bieten Sie an?"'
                            v-model="chatbot.smalltalkMaklerDienstleistungen"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage nach dem Impressum"
                            description='Fragen wie: "Wo finde ich Ihr Impressum?"'
                            v-model="chatbot.smalltalkMaklerImpressum"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage nach der E-Mail"
                            description='Fragen wie: "Wie erreiche ich Sie per Mail?" oder "Wie ist ihre Email Adresse?"'
                            v-model="chatbot.smalltalkMaklerMail"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage nach der Maklerprovision"
                            description='Die Höhe der Maklerprovision, auch Courtage genannt ist je nach Bundesland unterschiedlich und nicht gesetzlich geregelt. Die Provision für den Verkäufer und Käufer der Immobilie beträgt in der Regel jeweils 3,57 % inkl. MwSt. des notariell beurkundeten Kaufpreis'
                            v-model="chatbot.smalltalkMaklerMaklerProvision"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage nach den Öffnungszeiten"
                            description='Fragen wie: "Wann haben Sie geöffnet?" oder "Wann sind Sie erreichbar?"'
                            v-model="chatbot.smalltalkMaklerOpeningHours"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage nach einer Immobilie"
                            description='Fragen wie: "ich suche eine Immobilie" oder "Ich suche eine Wohnung"'
                            v-model="chatbot.smalltalkMaklerSearchPropertys"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage nach der Telefonnummer"
                            description='Fragen wie: "kann ich Sie anrufen?" oder "Anruf vereinbaren"'
                            v-model="chatbot.smalltalkMaklerTelefon"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage nach der Vermarktung"
                            description='Fragen wie: "Wollen Sie meine Immobilie verkaufen?" oder "Vermieten sie meine Wohnung?"'
                            v-model="chatbot.smalltalkMaklerVermarkten"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage nach der Adresse"
                            description='Fragen wie: "Wo finde ich Sie?" oder "Wie lautet Ihre Anschrift?"'
                            v-model="chatbot.smalltalkMaklerAddress"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage nach der AGB"
                            description='Fragen wie: "Wo ist Ihre AGB?"'
                            v-model="chatbot.smalltalkMaklerAgb"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage nach der Wertermittlung"
                            description='Fragen wie: "Was genau beinhaltet die Wertermittlung?"'
                            v-model="chatbot.smalltalkMaklerInhaltWertermittlung"
                            horizontal
                    />
                    <CTextarea
                            type="textarea"
                            label="Frage nach der Interessentendatenbank"
                            description='Fragen wie: "Ich möchte einen Suchauftrag für eine Immobilie zur Miete anlegen"'
                            v-model="chatbot.smalltalkMaklerInteressentendatenbankAufnahme"
                            horizontal
                    />

                  </CCol>
                </CRow>
              </CTab>
            </CTabs>
            <CButton @click="submitSettings" color="success">Speichern</CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DoubleBounceLoader from '@/components/loaders/DoubleBounceLoader'
import WysiwygEditor from '@/components/WysiwygEditor'
import Checkbox from '@/components/Checkbox'
import RadioButton from '@/components/RadioButton'
import commons from '@/commons'
import axios from 'axios'
import _ from 'lodash'
export default {
  name: 'ChatbotSettings',
  components: {
    RadioButton,
    DoubleBounceLoader,
    WysiwygEditor,
    Checkbox
  },
  data () {
    return {
      loading: false,
      saving: false,
      chatbot: {
        active: false,
        welcomeDsgvo: '',
        smalltalkMaklerBoss: '',
        smalltalkMaklerDatenschutz: '',
        smalltalkMaklerDienstleistungen: '',
        smalltalkMaklerImpressum: '',
        smalltalkMaklerMail: '',
        smalltalkMaklerMaklerProvision: '',
        smalltalkMaklerOpeningHours: '',
        smalltalkMaklerSearchPropertys: '',
        smalltalkMaklerTelefon: '',
        smalltalkMaklerVermarkten: '',
        smalltalkMaklerAddress: '',
        smalltalkMaklerAgb: '',
        smalltalkMaklerInhaltWertermittlung: '',
        smalltalkMaklerInteressentendatenbankAufnahme: '',
      }
    }
  },
  watch: {
    'chatbot.active': (newVal, oldVal) => {
      console.log(newVal, oldVal)
    }
  },
  computed: {
    computedChatbotActive () {
      return this.chatbot.active ? ['Aktiv'] : []
    }
  },
  mounted () {
    this.loading = true
    axios.get('/settings')
        .then(response => {
          this.chatbot = _.merge({}, this.chatbot, commons.deepenOneLevelToNested(response.data, '_', 'chatbot_'))
          this.loading = false
        })
        .catch(err => console.error(err))
  },
  methods: {
    async submitSettings() {
      this.saving = true
      let toSubmit = commons.oneLevelObject(this.chatbot, '_', 'chatbot_')
      try {
        await axios.post('/settings', toSubmit);
        await axios.get('/chatbot/syncSettings')
        this.$toaster.makeToast('Einstellungen erfolgreich gespeichert', 'Ihre Einstellungen wurden erfolgreich gespeichert.')
      } catch (err) {
        this.$toaster.makeToast('Fehler', 'Ihre Einstellungen konnten nicht gespeichert werden! Mehr dazu in der Console.')
        console.error(err)
      } finally {
        this.saving = false
      }
    }
  }
}
</script>
